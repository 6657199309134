import React, { useEffect, useState } from "react"
import usePageLoading from 'components/utils/usePageLoading'

import { TemplateContextProvider } from 'components/post/TemplateProvider'

import Header from "components/header"
import Sections from "components/sections"
import Footer from "components/footer"
import SEO  from "../components/seo"

//import Button from "vibemap-constants/design-system/components/button"
//import Card from "vibemap-constants/design-system/components/card"
//import Heading from "vibemap-constants/design-system/components/heading"

import "styles/pages.scss"

const TestIndex = ({ data, ...props }) => {

  // We're using this page to test page speed.
  const pageLoader = usePageLoading()

  return (
    <div className={`page ${pageLoader}`}>
      <SEO lang="en-US" />
      <main>
        <Header />
        Examples of different sections
        <div className='debug'>
          <div className='container'>
            <code>.container has max width of 1558px</code>
            <section>
              <code>section: padding is $unit-tiny = 1rem</code>
              <div className="container">
                <code>.container</code>
                <div className="columns">
                  <div className="column is-6 is-offset-3 has-text-centered">
                    <h3>Explore Cities </h3>
                    <div classname="body-text">
                      <p>Find yourself in the following cities and neighborhoods. Explore local events, places, attractions, and local things to.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              Sections have a max with of
            </section>
            <Sections />
        </div>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default TestIndex